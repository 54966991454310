<template>
  <div class="relative">
    <Spin v-if="loading" fix></Spin>
    <router-link to="/conferences" class="back">
      <Icon type="ios-arrow-back" />
      <span>Go back to Conferences</span>
    </router-link>

    <div class="head">
      <div class="flex">
        <div class="avatar">
          <img v-if="avatar" :src="avatar" alt="" />
        </div>
        <div class="box">
          <div class="name">{{ title }}</div>
          <div class="info">{{ host_name }}</div>
          <div class="info">{{ start_datest }}</div>
        </div>
      </div>

      <div>
        <!-- <Button
          type="primary"
          :loading="applyConferenceLoading"
          v-if="is_attending === null"
          @click="applyConference"
          >APPLY NOW</Button
        >

        <Button v-if="is_attending && is_attending.status === -1" type="error"
          >Application failed</Button
        >
        <Button v-if="is_attending && is_attending.status === 0" type="info"
          >Applying</Button
        > -->
        <!-- <Button v-if="is_attending && is_attending.status === 1" type="success"
          >Successful application</Button
        > -->
        <!-- <a
          class="enter-btn"
          v-if="is_attending && is_attending.status === 1"
          :href="virtual_conference_link"
          target="_blank"
        >
          <Button> Enter Conference <Icon type="md-open" /> </Button>
        </a> -->

        <a
          class="enter-btn"
          v-if="is_live"
          :href="virtual_conference_link"
          target="_blank"
        >
          <Button> Enter Conference <Icon type="md-open" /> </Button>
        </a>

        <!-- <div class="btn">ENTER CONFERENCE</div> -->
      </div>
    </div>

    <Ads adsnum="0"></Ads>

    <div class="flex min-height">
      <!-- <div class="tab">
        <div class="tab-head">
          <div
            v-if="role === 'attendee'"
            @click="tab = 'effective_exhibitor'"
            :class="['tab-title', { active: tab === 'effective_exhibitor' }]"
          >
            <span class="num">{{ effective_exhibitor.length }}</span>
            <span>Exhibitors</span>
          </div>

          <div
            @click="tab = 'effective_attendee'"
            :class="['tab-title', { active: tab === 'effective_attendee' }]"
          >
            <span class="num">{{ effective_attendee.length }}</span>
            <span>Attending Companies</span>
          </div>
        </div>
        <div class="tab-body">
          <div v-for="item in list" :key="item.id">
            <div class="item">
              <div
                class="active-user"
                @click="
                  company_name === item.company_name
                    ? $router.push('/company-profile')
                    : $router.push(`/company/${item.id}`)
                "
              >
                <div class="flex align-center">
                  <div class="avatar">
                    <img v-if="item.avatar.url" :src="item.avatar.url" />
                  </div>
                  <div class="name">{{ item.company_name }}</div>
                </div>
                <div class="icon">
                  <Icon type="ios-arrow-forward" />
                </div>
              </div>
            </div>
          </div>

          <div class="pd-20" v-if="list.length === 0">
            <empty />
          </div>
        </div>
      </div> -->

      <div class="tab sideInfo">
        <div class="logo">
          <img alt="" :src="avatar" />
        </div>
        <div class="info">
          <p>
            <Icon type="md-time" />
            <span>Start: {{ start_date }}</span>
          </p>
          <p style="padding-left: 46px">
            <span>End: {{ end_date }}</span>
          </p>
          <p>
            <Icon type="md-reorder" />
            <span>{{ exhibit_hall_size }}</span>
          </p>
        </div>
      </div>

      <div class="pd-20 flex1 right" style="padding:0 20px;padding-left:0">
        <div class="right-box rich-text" v-html="description"></div>
      </div>
    </div>

    <Ads adsnum="1"></Ads>
  </div>
</template>

<script>
import api from "@/api";
import { mapState } from "vuex";
const { _queryConference, _applyConference } = api;
// import empty from "@/components/empty";
import moment from "moment";
import Ads from "@/components/ads";

export default {
  components: {
    Ads
  },
  computed: {
    ...mapState("user", ["company_name", "role"]),
    list() {
      return this[this.tab];
    }
  },
  data() {
    return {
      tab: "effective_exhibitor",
      effective_attendee: [],
      effective_exhibitor: [],
      adsLists: [],
      loading: false,
      title: "",
      host_name: "",
      is_live: false,
      start_date: "",
      start_datest: "",
      end_date: "",
      description: "",
      virtual_conference_link: "",
      is_attending: null,
      avatar: "",
      exhibit_hall_size: "",
      applyConferenceLoading: false
    };
  },

  created() {
    const id = this.$route.params.id;
    this.id = id;
    this.queryConference();
    if (this.role === "exhibitor") {
      this.tab = "effective_attendee";
    }
  },

  methods: {
    applyConference() {
      this.applyConferenceLoading = true;
      _applyConference({ id: this.id })
        .then(() => {
          this.queryConference();
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.applyConferenceLoading = false;
        });
    },

    queryConference() {
      this.loading = true;
      _queryConference({
        id: this.id
      })
        .then(response => {
          const { data } = response;
          this.effective_attendee = data.effective_attendee;
          this.effective_exhibitor = data.effective_exhibitor;
          this.title = data.title;
          this.host_name = data.host_name;
          this.is_live = data.is_live;
          this.description = data.description;
          this.virtual_conference_link = data.virtual_conference_link;
          this.start_datest = moment
            .utc(data.start_date)
            .local()
            .format("YYYY-MM-DD");
          this.start_date = moment
            .utc(data.start_date)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");
          this.end_date = moment
            .utc(data.end_date)
            .local()
            .format("YYYY-MM-DD HH:mm:ss");
          this.is_attending = data.is_attending;
          this.avatar = data.avatar.url;
          this.exhibit_hall_size = data.exhibit_hall_size;
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>

<style lang="less" scoped>
.rich-text {
  overflow: hidden;
}
.relative {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}

.min-height {
  min-height: 800px;
}

.head {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  border-bottom: 1px solid #ccc;
  .avatar {
    width: 70px;
    height: 70px;
    border-radius: 6px;
    background: #eee;
    margin-right: 20px;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    .name {
      font-size: 18px;
      color: #000;
      font-weight: bold;
    }
    .info {
      color: #666;
      font-size: 14px;
    }
  }
  .btn {
    background: @primary-color;
    padding: 8px 20px;
    color: #fff;
    border-radius: 4px;
    display: flex;
    align-items: center;
    font-weight: bold;
  }
  .btn-active {
    color: @primary-color;
    background: rgba(245, 124, 0, 0.149019607843137);
  }
}

.advImg {
  margin: 20px auto;
  width: 80%;
  min-height: 100px;
  border: 1px solid #f2f2f2;
  overflow: hidden;
  img {
    width: 100%;
    height: auto;
  }
}

.tab {
  background: #fff;
  font-size: 14px;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  .tab-head {
    display: flex;
    border-bottom: 1px solid #ccc;
    .tab-title {
      cursor: pointer;
      &.active {
        background: @secondary-color;
        color: @primary-color;
        .num {
          background: @primary-color;
        }
      }
      &:last-child {
        border-right: none;
      }
      padding: 10px 20px;
      border-right: 1px solid #ccc;
      .num {
        min-width: 22px;
        display: inline-block;
        text-align: center;
        background: #ccc;
        color: #fff;
        padding: 2px;
        border-radius: 4px;
        margin-right: 6px;
        font-size: 12px;
      }
    }
  }
  .tab-body {
    .item {
      border-bottom: 1px solid #ccc;
      .active-user {
        padding: 10px;
        cursor: pointer;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .hide-user {
        padding: 10px;
        display: flex;
        align-items: center;
      }
      .avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background: @secondary-color;
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          width: 100%;
          height: 100%;
          display: block;
        }
        &.lock {
          background: rgba(0, 0, 0, 0.5);
          color: #fff;
        }
      }
      .name {
        color: @primary-color;
        text-decoration: underline;
      }
      .icon {
        color: @primary-color;
      }
    }
  }
}

.sideInfo {
  width: 20%;
  min-width: 250px;
  border: 1px solid #ccc;
  border-right: none;
  .logo {
    width: 100%;
    // height: 200px;
    overflow: hidden;
    margin-bottom: 15px;
    img {
      width: 100%;
    }
  }
  .info {
    color: #999;
    p {
      padding: 5px 20px;
      .ivu-icon {
        color: #666;
        padding-right: 4px;
        font-size: 16px;
      }
    }
  }
}

.enter-btn {
  /deep/ .ivu-btn {
    background-color: #000081;
    border-color: #000081;
    color: #f2f2f2;
  }
}

.right {
  overflow: hidden;

  .right-box {
    background: #fff;
    height: 100%;
    padding: 20px;
    border: 1px solid #ccc;
  }
}
</style>
